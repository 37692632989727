<template>
  <div v-loading="loading">
    <div class="filter">
      <div class="sub-title">各班比率分布</div>
      <classNums
        ref="classNums"
        style="margin-right: 8px"
        :placeholder="'选择班级'"
        :class-num-list="classNumList"
        @change="classNumChange"
      />
    </div>
    <a-table
      :key="tableKey"
      :pagination="false"
      :columns="columns"
      style="margin-bottom: 24px"
      :data-source="tableData"
      bordered
      :scroll="{ x: 'max-content' }"
    ></a-table>
    <div class="dataType">
      <a-select
        v-model="dataType"
        style="width: 150px"
        allow-clear
        @change="ratioChange"
      >
        <a-select-option
          v-for="(item, index) in ratios"
          :key="index"
          :value="item.name"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </div>
    <div ref="chart" class="chart"></div>
  </div>
</template>

<script>
import { getstatclassoption } from "@/core/api/academic/common";
import classNums from "../../../components/classNums.vue";
import { getunionreportclasscompetitiveclassratio } from "@/core/api/academic/union";
import * as echarts from "echarts";
export default {
  name: "",
  components: {
    classNums,
  },
  props: {
    subjectId: {
      type: [String, Number],
      default: "",
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      classNumList: [],
      tableData: [],
      dataType: null,
      selectOptions: [],
      columns: [],
      classNum: [],
      ratios: [],
      tableKey: Math.random(),
    };
  },
  mounted() {
    this.getClassNums();
  },
  methods: {
    hasFieldCols(field, index) {
      let cols = [
        {
          title: "人数",
          align: "center",
          dataIndex: "total" + index,
          customRender: (text, record) => record.ratios[index].total,
        },
        {
          title: "占比",
          align: "center",
          dataIndex: "proportion" + index,
          customRender: (text, record) => record.ratios[index].proportion + "%",
        },
      ];
      if (this.topData.isSchoolDisplayReportClassRank == 1) {
        cols.push({
          title: "排名",
          align: "center",
          dataIndex: "rank" + index,
          customRender: (text, record) =>
            ["本校", "整体"].includes(record.classNum)
              ? "-"
              : record.ratios[index].rank,
        });
      }
      return cols;
    },
    async getData() {
      this.tableData = [];
      this.loading = true;
      const res = await getunionreportclasscompetitiveclassratio({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
        subjectId: this.subjectId,
        classNums: this.classNum.length ? this.classNum.join(",") : null,
      });
      this.ratios = [];
      if (res.data.data.length) {
        this.ratios = res.data.data[0].ratios;
        if (this.ratios.length) {
          this.dataType = this.ratios[0].name;
        }
      }
      this.columns = [
        {
          title: "班级",
          align: "center",
          dataIndex: "classNum",
        },
        {
          title: this.subjectId === 0 ? "班主任" : "任课教师",
          align: "center",
          dataIndex: "teacherName",
          customRender: (text, record) => {
            if (["整体高位班级", "整体", "本校"].includes(record.classNum)) {
              return "-";
            }

            return record.teacherName;
          },
        },
        {
          title: "参统考生",
          align: "center",
          dataIndex: "statTotal",
        },
        {
          title: "平均分",
          align: "center",
          dataIndex: "averageScore",
        },
        ...this.ratios.map((item, index) => ({
          title: item.name,
          align: "center",
          dataIndex: "ratios" + index,
          children: [...this.hasFieldCols("ratios", index)],
        })),
      ];
      if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.tableData = [...res.data.data];
      } else {
        this.tableData = res.data.data.filter(
          (item) => item.classNum != "整体"
        );
      }
      this.tableKey = Math.random();
      this.loading = false;
      this.initCharts();
    },
    async getClassNums() {
      const res = await getstatclassoption({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.classNumList = res.data.data.map((item) => item.name);
    },
    ratioChange() {
      this.initCharts();
    },
    classNumChange(val) {
      this.classNum = val;
      this.getData();
    },
    initCharts() {
      let schoolData = this.tableData.find((item) => item.classNum === "本校");
      let totalData = this.tableData.find((item) => item.classNum === "整体");
      let classData = this.tableData.filter(
        (item) => !["本校", "整体"].includes(item.classNum)
      );
      let series = [
        {
          name: this.dataType,
          type: "bar",
          data: [
            ...classData.map(
              (item) =>
                item.ratios.find((it) => it.name === this.dataType).proportion
            ),
          ],
          barMaxWidth: 40,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: "top",
                textStyle: {
                  color: "inherit",
                  fontSize: 9,
                },
              },
            },
          },
        },
        {
          name: "校均值",
          type: "line",
          smooth: true,
          data: [
            ...classData.map(
              () =>
                schoolData.ratios.find((it) => it.name === this.dataType)
                  .proportion
            ),
          ],
        },
      ];
      if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        series.push({
          name: "整体",
          type: "line",
          smooth: true,
          data: [
            ...classData.map(
              () =>
                totalData.ratios.find((it) => it.name === this.dataType)
                  .proportion
            ),
          ],
        });
      }
      let options = {
        grid: {
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        dataZoom: [
          //滚动条
          {
            show: classData.length > 5,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: classData.length - (classData.length - 5),
            xAxisIndex: [0],
            bottom: "3%",
            left: "center",
          },
        ],
        legend: {},
        color: ["#2474ED", "#5AD8A6", "#FF8857"],
        xAxis: [
          {
            type: "category",
            data: [...classData.map((item) => item.classNum)],
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: {
          type: "value",
        },
        series: [...series],
      };

      var chart = echarts.init(this.$refs.chart);
      chart.setOption(options);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../union.scss";
.filter {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.dataType {
  margin-bottom: 24px;
}
.chart {
  width: 100%;
  height: 380px;
}
</style>
