<template>
  <div class="container">
    <div class="title">整体竞争力</div>
    <div class="sub-title">成绩分布</div>
    <div class="filter">
      <a-space>
        <span>分数段区间：</span>
        <span>上限：</span>
        <a-input-number
          v-model="search.high"
          style="width: 64px"
        ></a-input-number>
        <span>分</span>
        <span>下限：</span>
        <a-input-number
          v-model="search.low"
          style="width: 64px"
        ></a-input-number>
        <span>分</span>
        <span>间隔：</span>
        <a-input-number
          v-model="search.interval"
          style="width: 64px"
        ></a-input-number>
        <span>分</span>
        <a-button type="primary" @click="submit">确定</a-button></a-space
      >
    </div>
    <div ref="chart" class="charts"></div>
    <div class="tip" style="margin-bottom: 18px">
      横轴为分数段区间，从左到右分数从高到低，具体间隔可在右上角自由设置，纵轴是该分数区间的人数占比(%)，线越在上方的学校代表该分数区间人数占比越高,
      查看图表时，可关注高分群体(左侧)哪个学校线越高，则好学生越多，反之低分群体(右侧)哪个学校线越高则后进生越多。
    </div>
    <schoolCompeitiveness :top-data="topData" />
  </div>
</template>

<script>
import * as echarts from "echarts";
import schoolCompeitiveness from "./schoolCompeitiveness.vue";
import { getunionreportoverallcompetitivescoredistribution } from "@/core/api/academic/union";
export default {
  name: "",
  components: {
    schoolCompeitiveness,
  },
  props: {
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: {},
      dataSource: [],
    };
  },
  mounted() {
    this.search = {
      high: this.topData.segmentMaximum,
      low: this.topData.segmentMinimum,
      interval: this.topData.segmentGap,
    };
    this.getData();
  },
  methods: {
    submit() {
      if (this.search.high <= this.search.low) {
        this.$message({
          type: "warning",
          message: "上限必须大于下限",
          showClose: true,
        });
      } else if (this.search.interval === 0) {
        this.$message({
          type: "warning",
          message: "间隔不能为0",
          showClose: true,
        });
      } else {
        this.getData();
      }
    },
    async getData() {
      const res = await getunionreportoverallcompetitivescoredistribution({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
        ...this.search,
      });
      this.dataSource = res.data.data;
      this.initCharts();
    },
    initCharts() {
      let segments = [];
      if (this.dataSource.length) {
        segments = this.dataSource[0].segments;
      }

      let chartData = [...this.dataSource];
      if (this.topData.isSchoolDisplayReportUnionAverageScore != 1) {
        chartData = this.dataSource.filter((item) => item.schoolName != "整体");
      }
      let options = {
        grid: {
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        dataZoom: [
          //滚动条
          {
            show: segments.length > 8,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: segments.length - (segments.length - 8),
            xAxisIndex: [0],
            bottom: "3%",
            left: "center",
          },
        ],
        legend: {},
        color: ["#5B8FF9", "#5AD8A6", "#5D7092", "#F6BD16", "#E8684A"],
        xAxis: [
          {
            type: "category",
            data: [...segments.map((item) => item.segment)],
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              rotate: segments.length > 8 ? 35 : 0,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: {
          type: "value",
        },
        series: [
          ...chartData.map((item) => ({
            name: item.schoolName,
            type: "line",
            smooth: true,
            data: [...item.segments.map((it) => it.proportion)],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "inherit",
                    fontSize: 9,
                  },
                },
              },
            },
          })),
        ],
      };

      var chart = echarts.init(this.$refs.chart);
      chart.setOption(options);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../union.scss";
.filter {
  margin-bottom: 18px;
}
.charts {
  width: 100%;
  margin-bottom: 18px;
  height: 380px;
}
</style>
