<template>
  <div ref="development" class="container">
    <div class="title">发展性分析</div>
    <examTransformVue
      :stat-id="$route.query.id"
      :exam-arr="examArr"
      style="margin-bottom: 18px"
      @submit="getExamId"
    />

    <div class="btns">
      <div
        v-for="(item, index) in subjectList"
        :key="index"
        :class="['btns-item', subjectId === item.id ? 'btns-active' : '']"
        @click="subjectClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="operate">
      <a-radio-group
        v-model="dataType"
        button-style="solid"
        @change="dataTypeChange"
      >
        <a-radio-button value="1"> 超均率 </a-radio-button>
        <a-radio-button v-if="topData.topInfoOnlineVOs.length" value="2">
          上线情况
        </a-radio-button>
        <a-radio-button v-if="topData.topInfoRatioVOs.length" value="3">
          比率
        </a-radio-button>
        <a-radio-button value="4"> 前N名 </a-radio-button>
        <a-radio-button value="5"> 其他指标 </a-radio-button>
      </a-radio-group>
    </div>
    <div v-if="['2', '3', '4'].includes(dataType)" class="params">
      <a-select
        :key="dataType"
        v-model="params"
        style="width: 200px"
        not-found-content="暂无数据"
        @change="paramsChange"
      >
        <a-select-option
          v-for="(item, index) in options"
          :key="index"
          :value="item.name"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </div>
    <div v-if="dataType == 5" class="params">
      <a-select
        :key="dataType"
        v-model="params"
        style="width: 200px"
        not-found-content="暂无数据"
        @change="paramsChange"
      >
        <a-select-option
          v-for="(item, index) in options"
          :key="index"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <chart
      v-if="defineOptions && series.length"
      :key="chartKey"
      :define-options="defineOptions"
      :series="series"
    />
    <no-data v-else />
    <a-table
      :pagination="false"
      :columns="columns"
      :data-source="tableData"
      style="margin-bottom: 24px"
      bordered
      :scroll="{ x: 'max-content' }"
    ></a-table>
    <div class="tip">
      <div class="intent">注：</div>
      <div>
        <div>折线图——学校&区域整体历次考试指标值;</div>
        <div>
          1.
          同色折线不同考试节点对比：最近一次考试与历次考试进行对比，用于查看学校或区域整体考试指标的变化趋势
        </div>
        <div>
          2.
          同一考试节点不同颜色值对比：每次考试学校与区域整体指标差距，涨/跌柱高度代表差值大小。
        </div>
        <div>
          3.
          多涨/跌柱对比：历次考试中，学校与区域整体指标差距的变化情况。可选择同比、环比及周期性对比的角度进行发展性的分析。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import examTransformVue from "../components/examTransform.vue";
import { examTypeOptions } from "@/core/util/constdata";
import chart from "@/views/newExamAcademic/components/charts.vue";
import { getstatsubjectoption } from "@/core/api/academic/common";
import { getstatuniondevelopanalysiscomparelist } from "@/core/api/academic/common";
import { getunionreportdevelopanalysis } from "@/core/api/academic/union";
export default {
  name: "Development",
  components: {
    examTransformVue,
    chart,
  },
  props: {
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      defineOptions: null,
      series: [],
      type: "2",
      examList: [],
      visible: false,
      confirmLoading: false,
      examTypeOptions: examTypeOptions,
      examTypes: [],
      category: null,
      subjectList: [],
      electiveId: null,
      electives: [],
      subjectId: 0,
      classNum: null,
      classNumList: [],
      classNumKey: Math.random(),
      compareList: [],
      examArr: [],
      dataType: "1",
      params: "",
      options: [],
      chartKey: Math.random(),
      tableData: [],
      columns: [],
      compareIds: [],
    };
  },
  mounted() {
    this.getCompareList();
  },
  methods: {
    async getSubectList() {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        requestPage: 1,
        viewSchoolId: this.$route.query.schoolId,
        hasTotal: 1,
      });
      this.subjectList = res.data.data;
      if (res.data.data.length) {
        this.subjectId = res.data.data[0].id;
        this.getData();
      }
    },
    getExamId(val) {
      this.compareIds = val;
      this.getData();
    },
    async getCompareList() {
      const res = await getstatuniondevelopanalysiscomparelist({
        statId: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
        examMode: this.$route.query.ruleId > 0 ? 2 : 1,
        examTypes:
          this.examTypes && this.examTypes.length
            ? this.examTypes.toString()
            : "",
      });
      this.compareList = res.data.data;
      if (res.data.data.length > 5) {
        let data = res.data.data.slice(0, 5);
        this.compareIds = data.map((item) => item.id);
      } else {
        this.compareIds = res.data.data.map((item) => item.id);
      }
      this.getSubectList();

      // this.closeLoading(loadingInstance, io);
    },
    subjectClick(item) {
      this.subjectId = item.id;
      this.getData();
    },
    async getData() {
      const res = await getunionreportdevelopanalysis({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
        // contrastStatId: "8937,8736,8105",
        subjectId: this.subjectId,
        contrastStatId: this.compareIds ? this.compareIds.join(",") : "",
      });
      this.examArr = res.data.data;
      this.tableData = res.data.data.flatMap((item) => {
        if (this.topData.isSchoolDisplayReportUnionAverageScore != 1) {
          let shcoolList = item.schools.filter(
            (it) => it.schoolName !== "整体"
          );
          return shcoolList.map((it) => ({ ...item, ...it }));
        } else {
          return item.schools.map((it) => ({ ...item, ...it }));
        }
      });
      this.dataTypeChange();
    },
    paramsChange() {
      if (this.dataType != "5") {
        const enmu = {
          2: "ratios",
          3: "topSections",
        };
        let key = enmu[this.dataType];
        this.setchart(key);
      } else {
        this.setOther();
      }
    },
    dataTypeChange() {
      this.defineOptions = null;
      this.options = [];
      this.series = [];
      if (this.examArr.length) {
        this.defineOptions = {
          legend: {
            itemGap: 20,
            type: "scroll",
            orient: "horizontal",
          },
          xAxis: {
            type: "category",
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              interval: 0,
              width: 100,
              overflow: "breakAll",
            },
            data: [...this.examArr.map((item) => item.name)],
          },
        };
        let schools = [];
        if (this.examArr.length) schools = this.examArr[0].schools;
        const data = this.examArr.flatMap((item) => item.schools);

        if (this.dataType == "1") {
          let noCurrent = schools.filter((item) => item.schoolName !== "整体");
          this.series = noCurrent.map((item) => {
            const sameClass = data.filter(
              (it) => it.schoolName === item.schoolName
            );
            return {
              name: "超均率",
              type: "line",
              label: {
                show: true,
                textStyle: {
                  color: "inherit",
                  fontSize: 9,
                },
                position: "top",
              },
              data: sameClass.map((item) => item.overAverageRate || 0),
            };
          });
          this.columns = [
            {
              title: "考试名称",
              dataIndex: "name",
              align: "center",
              customRender: (text, record, index) => {
                let targetIndex = this.tableData.findIndex(
                  (item) => item.name === record.name
                );
                let arr = this.tableData.filter(
                  (item) => item.name === record.name
                );
                let obj = {
                  children: record.name,
                  attrs: {},
                };
                if (index == targetIndex) {
                  obj.attrs.rowSpan = arr.length;
                } else {
                  obj.attrs.rowSpan = 0;
                }
                return obj;
              },
            },
            {
              title: "学校",
              dataIndex: "schoolName",
              align: "center",
            },
            {
              title: "参统学生",
              dataIndex: "statTotal",
              align: "center",
            },
            {
              title: "平均分",
              dataIndex: "averageScore",
              align: "center",
            },
            {
              title: "超均率（%）",
              dataIndex: "overAverageRate",
              align: "center",
            },
          ];
        } else if (this.dataType == "2") {
          if (schools.length) {
            if (schools[0].onlines && schools[0].onlines.length) {
              let onlines = schools[0].onlines;
              this.columns = [
                {
                  title: "考试名称",
                  dataIndex: "name",
                  align: "center",
                  customRender: (text, record, index) => {
                    let targetIndex = this.tableData.findIndex(
                      (item) => item.name === record.name
                    );
                    let arr = this.tableData.filter(
                      (item) => item.name === record.name
                    );
                    let obj = {
                      children: record.name,
                      attrs: {},
                    };
                    if (index == targetIndex) {
                      obj.attrs.rowSpan = arr.length;
                    } else {
                      obj.attrs.rowSpan = 0;
                    }
                    return obj;
                  },
                },
                {
                  title: "学校",
                  dataIndex: "schoolName",
                  align: "center",
                },
                {
                  title: "参统学生",
                  dataIndex: "statTotal",
                  align: "center",
                },
                ...onlines.map((item, index) => ({
                  title: item.name,
                  align: "center",
                  dataIndex: "onlines" + index,
                  children: [
                    {
                      title: "人数",
                      align: "center",
                      dataIndex: "total" + index,
                      customRender: (text, record) =>
                        record.onlines[index].total,
                    },
                    {
                      title: "占比",
                      align: "center",
                      dataIndex: "proportion" + index,
                      customRender: (text, record) =>
                        record.onlines[index].proportion + "%",
                    },
                  ],
                })),
              ];
              this.options = schools[0].onlines;
              this.params = this.options[0].name;
              this.setchart("onlines");
            }
          }
        } else if (this.dataType == "3") {
          if (schools.length) {
            if (schools[0].ratios && schools[0].ratios.length) {
              let ratios = schools[0].ratios;
              this.columns = [
                {
                  title: "考试名称",
                  dataIndex: "name",
                  align: "center",
                  customRender: (text, record, index) => {
                    let targetIndex = this.tableData.findIndex(
                      (item) => item.name === record.name
                    );
                    let arr = this.tableData.filter(
                      (item) => item.name === record.name
                    );
                    let obj = {
                      children: record.name,
                      attrs: {},
                    };
                    if (index == targetIndex) {
                      obj.attrs.rowSpan = arr.length;
                    } else {
                      obj.attrs.rowSpan = 0;
                    }
                    return obj;
                  },
                },
                {
                  title: "学校",
                  dataIndex: "schoolName",
                  align: "center",
                },
                {
                  title: "参统学生",
                  dataIndex: "statTotal",
                  align: "center",
                },
                ...ratios.map((item, index) => ({
                  title: item.name,
                  align: "center",
                  dataIndex: "ratios" + index,
                  children: [
                    {
                      title: "人数",
                      align: "center",
                      dataIndex: "total" + index,
                      customRender: (text, record) =>
                        record.ratios[index].total,
                    },
                    {
                      title: "占比",
                      align: "center",
                      dataIndex: "proportion" + index,
                      customRender: (text, record) =>
                        record.ratios[index].proportion + "%",
                    },
                  ],
                })),
              ];
              this.options = schools[0].ratios;
              this.params = this.options[0].name;
              this.setchart("ratios");
            }
          }
        } else if (this.dataType == "4") {
          if (schools.length) {
            if (schools[0].topSections && schools[0].topSections.length) {
              let topSections = schools[0].topSections;
              this.columns = [
                {
                  title: "考试名称",
                  dataIndex: "name",
                  align: "center",
                  customRender: (text, record, index) => {
                    let targetIndex = this.tableData.findIndex(
                      (item) => item.name === record.name
                    );
                    let arr = this.tableData.filter(
                      (item) => item.name === record.name
                    );
                    let obj = {
                      children: record.name,
                      attrs: {},
                    };
                    if (index == targetIndex) {
                      obj.attrs.rowSpan = arr.length;
                    } else {
                      obj.attrs.rowSpan = 0;
                    }
                    return obj;
                  },
                },
                {
                  title: "学校",
                  dataIndex: "schoolName",
                  align: "center",
                },
                {
                  title: "参统学生",
                  dataIndex: "statTotal",
                  align: "center",
                },
                ...topSections.map((item, index) => ({
                  title: item.name,
                  align: "center",
                  dataIndex: "topSections" + index,
                  children: [
                    {
                      title: "人数",
                      align: "center",
                      dataIndex: "total" + index,
                      customRender: (text, record) =>
                        record.topSections[index].total,
                    },
                    {
                      title: "占比",
                      align: "center",
                      dataIndex: "proportion" + index,
                      customRender: (text, record) =>
                        record.topSections[index].proportion + "%",
                    },
                  ],
                })),
              ];
              this.options = schools[0].topSections;
              this.params = this.options[0].name;
              this.setchart("topSections");
            }
          }
        } else if (this.dataType == "5") {
          if (schools.length) {
            this.columns = [
              {
                title: "考试名称",
                dataIndex: "name",
                align: "center",
                customRender: (text, record, index) => {
                  let targetIndex = this.tableData.findIndex(
                    (item) => item.name === record.name
                  );
                  let arr = this.tableData.filter(
                    (item) => item.name === record.name
                  );
                  let obj = {
                    children: record.name,
                    attrs: {},
                  };
                  if (index == targetIndex) {
                    obj.attrs.rowSpan = arr.length;
                  } else {
                    obj.attrs.rowSpan = 0;
                  }
                  return obj;
                },
              },
              {
                title: "学校",
                dataIndex: "schoolName",
                align: "center",
              },
              {
                title: "参统学生",
                dataIndex: "statTotal",
                align: "center",
              },
              {
                title: "最高分",
                dataIndex: "highestScore",
                align: "center",
              },
              {
                title: "最低分",
                dataIndex: "lowestScore",
                align: "center",
              },
              {
                title: "标准差",
                dataIndex: "standardDeviation",
                align: "center",
              },
            ];
            this.options = [
              {
                label: "最高分",
                value: "highestScore",
              },
              {
                label: "最低分",
                value: "lowestScore",
              },
              {
                label: "标准差",
                value: "standardDeviation",
              },
            ];
            this.params = "highestScore";
            this.setOther();
          }
        }
      }
      this.chartKey = Math.random();
    },
    setchart(field) {
      this.series = [];
      let schools = this.examArr[0].schools;
      const data = this.examArr.flatMap((item) => item.schools);
      this.series = schools.map((item) => {
        const a = data.filter((it) => item.schoolName === it.schoolName);
        const fin = a.map((i) => {
          const itemData = i[field].find((n) => n.name === this.params);
          if (itemData) {
            return itemData.proportion;
          } else {
            return 0;
          }
        });
        return {
          name: item.schoolName,
          type: "line",
          label: {
            show: true,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
            position: "top",
          },
          data: fin,
        };
      });
      this.chartKey = Math.random();
    },
    setOther() {
      this.series = [];
      let schools = this.examArr[0].schools;
      const data = this.examArr.flatMap((item) => item.schools);
      this.series = schools.map((item) => {
        const a = data.filter((it) => item.schoolName === it.schoolName);
        const fin = a.map((i) => i[this.params]);
        return {
          name: item.schoolName,
          type: "line",
          label: {
            show: true,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
            position: "top",
          },
          data: fin,
        };
      });
      this.chartKey = Math.random();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../union.scss";
.tabs {
  //   margin-bottom: 18px;
}
.compare {
  background: #f3f8ff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  color: #3e4551;
  font-size: 14px;
  margin-bottom: 18px;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .btns-item {
    border-radius: 4px;
    border: 1px solid #d5d6db;
    box-sizing: border-box;
    padding: 6px 18px;
    color: #0a1119;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 18px;
    transition: all 0.2s ease-in-out;
    &:hover {
      border-color: #2474ed;
      background-color: #2474ed;
      color: #ffff;
    }
  }
  .btns-active {
    border-color: #2474ed;
    background-color: #2474ed;
    color: #ffff;
  }
  .btns-item + .btns-item {
    margin-left: 8px;
  }
}
.exam-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  .filter-item + .filter-item {
    margin-left: 8px;
  }
}
::v-deep .ant-select-selection--single {
  height: 35px;
  line-height: 35px;
}
.operate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 18px;
}
.params {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 18px;
}
</style>
