<template>
  <div class="container">
    <div class="title">高分群体</div>
    <div class="sub-title">高分人群分布</div>
    <div class="operate">
      <div class="filter">
        <a-space>
          <span>自定义高分范围：</span>
          <span>整体位置</span>
          <a-input-number v-model="rank" style="width: 120px"></a-input-number>
          <span>名</span>
          <a-button type="primary" @click="searchSubmit">确定</a-button>
        </a-space>
      </div>
      <a-radio-group
        v-model="dataType"
        button-style="solid"
        @change="dataTypeChange"
      >
        <a-radio-button value="1"> 人数 </a-radio-button>
        <a-radio-button value="2"> 占比 </a-radio-button>
      </a-radio-group>
    </div>
    <div ref="chart" class="chart"></div>
    <a-table
      :pagination="false"
      :columns="columns"
      :data-source="tableData"
      bordered
      style="width: 100%; margin-bottom: 24px"
    ></a-table>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getunionreporthighscorestudentdistribution } from "@/core/api/academic/union";
export default {
  name: "",
  props: {
    topData: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      rank: 0,
      dataType: "1",
      tableData: [],
      columns: [
        {
          title: "班级",
          align: "center",
          width: 85,
          dataIndex: "classNum",
        },
        {
          title: "班主任",
          align: "center",
          width: 100,
          dataIndex: "teacherName",
          customRender: (text, record) => {
            return ["整体高位班级", "整体", "本校"].includes(record.classNum)
              ? "-"
              : record.teacherName;
          },
        },
        {
          title: "高分人群",
          width: 120,
          align: "center",
          dataIndex: "total",
        },
        {
          title: "占比",
          width: 120,
          align: "center",
          dataIndex: "proportion",
          customRender: (text, record) => record.proportion + "%",
        },
        {
          title: "学生名单",
          align: "center",
          wdith: 400,
          dataIndex: "students",
          customRender: (text, record) => {
            if (["整体高位班级", "本校", "整体"].includes(record.classNum)) {
              return "-";
            }
            let data =
              record.extendPo || record.students.length <= 9
                ? record.students
                : record.students.slice(0, 9);
            let button;
            if (record.students.length > 9) {
              button = record.extendPo ? (
                <a-button
                  type="link"
                  vOn:click={() => this.$set(record, "extendPo", false)}
                >
                  收起
                </a-button>
              ) : (
                <a-button
                  type="link"
                  vOn:click={() => this.$set(record, "extendPo", true)}
                >
                  查看更多
                </a-button>
              );
            }
            if (data.length === 0) {
              return "-";
            } else {
              return (
                <div class="nameList">
                  {...data.map((item) => (
                    <a-button
                      type="link"
                      vOn:click={() => this.handleClick(record, item)}
                    >
                      {item.name}
                    </a-button>
                  ))}
                  {button}
                </div>
              );
            }
          },
        },
      ],
    };
  },
  mounted() {
    this.rank = this.topData.rankMaximum;
    this.getData();
  },
  methods: {
    handleClick(record, item) {
      const { href } = this.$router.resolve({
        path: "/academic/reportStat/studentReport",
        query: {
          ...this.$route.query,
          studentName: item.name,
          studentId: item.studentId,
          subjectId: 0,
          classNum: record.classNum,
          level: this.$route.query.level,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    dataTypeChange() {
      this.initCharts();
    },
    searchSubmit() {
      this.getData();
    },
    async getData() {
      const res = await getunionreporthighscorestudentdistribution({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
        rank: this.rank,
      });
      if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.tableData = res.data.data;
      } else {
        this.tableData = res.data.data.filter(
          (item) => item.classNum != "整体"
        );
      }
      this.initCharts();
    },
    studentClick() {},
    initCharts() {
      let options = {
        grid: {
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        dataZoom: [
          //滚动条
          {
            show: this.tableData.length > 5,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: this.tableData.length - (this.tableData.length - 5),
            xAxisIndex: [0],
            bottom: "3%",
            left: "center",
          },
        ],
        legend: {},
        color: ["#2474ED", "#5AD8A6", "#5AD8A6", "#5AD8A6", "#5AD8A6"],
        xAxis: [
          {
            type: "category",
            data: [...this.tableData.map((item) => item.classNum)],
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              rotate: 15,
            },
          },
        ],
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: this.dataType == "1" ? "人数" : "占比",
            type: "bar",
            data: [
              ...this.tableData.map(
                (item) => item[this.dataType == "1" ? "total" : "proportion"]
              ),
            ],
            barMaxWidth: 40,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "inherit",
                    fontSize: 9,
                  },
                },
              },
            },
          },
        ],
      };

      var chart = echarts.init(this.$refs.chart);
      chart.setOption(options);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../union.scss";
.container {
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .chart {
    width: 100%;
    height: 380px;
  }
}
.nameList {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  span {
    box-sizing: border-box;
    padding: 8px;
  }
  .student {
    color: #2474ed;
    font-size: 14px;
    cursor: pointer;
    margin-right: 8px;
    margin-bottom: 8px;
  }
}
</style>
