<template>
  <div>
    <div class="sub-title">校整体竞争力</div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="false"
      :row-class-name="rowClassName"
      :scroll="{ x: 'max-content' }"
    ></a-table>
    <div class="filter">
      <a-radio-group v-model="type" button-style="solid" @change="typeChange">
        <a-radio-button value="1"> 平均分 </a-radio-button>
        <a-radio-button v-if="onlines.length" value="2">
          上线情况
        </a-radio-button>
        <a-radio-button v-if="ratios.length" value="3"> 比 率 </a-radio-button>
        <a-radio-button v-if="topSections.length" value="4">
          前N名
        </a-radio-button>
        <a-radio-button value="5"> 其他指标 </a-radio-button>
      </a-radio-group>
    </div>
    <div class="select">
      <a-select
        v-if="type != 1"
        v-model="value"
        style="width: 150px"
        not-found-content="暂无数据"
        placeholder="请选择"
        @change="handleChange"
      >
        <a-select-option
          v-for="(item, index) in selectOptions"
          :key="index"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <div ref="chart" class="chart"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getunionreportoverallcompetitiveschooloverallcompetitive } from "@/core/api/academic/union";
export default {
  name: "",
  props: {
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      type: "1",
      columns: [],
      tableData: [],
      selectOptions: [],
      topSections: [],
      ratios: [],
      onlines: [],
      value: undefined,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    hasAvgCols(res) {
      let cols = [
        {
          title: "分值（与整体差值）",
          align: "center",
          dataIndex: "averageScore",
          customRender: (text, record) => {
            let currentSchool = res.data.data.find(
              (item) => item.schoolName === "整体"
            );
            let diff = currentSchool
              ? (record.averageScore - currentSchool.averageScore).toFixed(2)
              : "-";

            if (diff > 0) {
              return (
                <span>
                  {record.averageScore}
                  <span style="color:#F57574">+{diff}</span>{" "}
                </span>
              );
            } else if (diff < 0) {
              return (
                <span>
                  {record.averageScore}
                  <span style="color:#2474ED">{diff}</span>{" "}
                </span>
              );
            } else {
              return <span>{record.averageScore}</span>;
            }
          },
        },
      ];
      if (this.topData.isSchoolDisplayReportSchoolRank == 1) {
        cols.push({
          title: "排名",
          align: "center",
          dataIndex: "averageScoreRank",
          customRender: (text, record) =>
            ["整体"].includes(record.schoolName)
              ? "-"
              : record.averageScoreRank,
        });
      }
      return cols;
    },
    hasRatiosCols(index) {
      let cols = [
        {
          title: "人数",
          align: "center",
          dataIndex: "ratios-total-" + index,
          customRender: (text, record) => record.ratios[index].total,
        },
        {
          title: "占比（%）",
          align: "center",
          dataIndex: "ratios-proportion-" + index,
          customRender: (text, record) => record.ratios[index].proportion,
        },
      ];
      if (this.topData.isSchoolDisplayReportSchoolRank == 1) {
        cols.push({
          title: "排名",
          align: "center",
          dataIndex: "ratios-rank-" + index,
          customRender: (text, record) =>
            ["整体"].includes(record.schoolName)
              ? "-"
              : record.ratios[index].rank,
        });
      }
      return cols;
    },
    hasTopsectionsCols(index) {
      let cols = [
        {
          title: "上线人数 ",
          align: "center",
          dataIndex: "topSection-total-" + index,
          customRender: (text, record) => record.topSections[index].total,
        },
        {
          title: "占比（%）",
          align: "center",
          dataIndex: "topSection-proportion-" + index,
          customRender: (text, record) => record.topSections[index].proportion,
        },
      ];
      if (this.topData.isSchoolDisplayReportSchoolRank == 1) {
        cols.push({
          title: "排名",
          align: "center",
          dataIndex: "topSections-rank-" + index,
          customRender: (text, record) =>
            ["整体"].includes(record.schoolName)
              ? "-"
              : record.topSections[index].rank,
        });
      }
      return cols;
    },
    hasOnlinesCols(index) {
      let cols = [
        {
          title: "上线人数 ",
          align: "center",
          dataIndex: "onlines-total-" + index,
          customRender: (text, record) => record.onlines[index].total,
        },
        {
          title: "占比（%）",
          align: "center",
          dataIndex: "onlines-proportion-" + index,
          customRender: (text, record) => record.onlines[index].proportion,
        },
      ];
      if (this.topData.isSchoolDisplayReportSchoolRank == 1) {
        cols.push({
          title: "排名",
          align: "center",
          dataIndex: "onlines-rank-" + index,
          customRender: (text, record) =>
            ["整体"].includes(record.schoolName)
              ? "-"
              : record.onlines[index].rank,
        });
      }
      return cols;
    },
    async getData() {
      const res =
        await getunionreportoverallcompetitiveschooloverallcompetitive({
          id: this.$route.query.id,
          viewSchoolId: this.$route.query.schoolId,
        });
      this.ratios = [];
      this.onlines = [];
      this.topSections = [];
      if (res.data.data.length) {
        this.ratios = res.data.data[0].ratios;
        this.onlines = res.data.data[0].onlines;
        this.topSections = res.data.data[0].topSections;
      }
      this.columns = [
        {
          title: "学校",
          align: "center",
          dataIndex: "schoolName",
        },
        {
          title: "参统考生",
          align: "center",
          dataIndex: "statTotal",
        },
        {
          title: "平均分",
          align: "center",
          dataIndex: "averageScorePa",
          children: [...this.hasAvgCols(res)],
        },
        ...this.ratios.map((item, index) => ({
          title: item.name,
          align: "center",
          dataIndex: "ratios" + index,
          children: [...this.hasRatiosCols(index)],
        })),
        ...this.topSections.map((item, index) => ({
          title: item.name,
          align: "center",
          dataIndex: "topSections" + index,
          children: [...this.hasTopsectionsCols(index)],
        })),
        ...this.onlines.map((item, index) => ({
          title: item.name,
          align: "center",
          dataIndex: "onlines" + index,
          children: [...this.hasOnlinesCols(index)],
        })),
        {
          title: "最高分",
          align: "center",
          dataIndex: "highestScore",
        },
        {
          title: "最低分",
          align: "center",
          dataIndex: "lowestScore",
        },
        {
          title: "中位数",
          align: "center",
          dataIndex: "median",
        },
        {
          title: "全距",
          align: "center",
          dataIndex: "rangeScore",
        },
        {
          title: "离均差（整体均值）",
          align: "center",
          dataIndex: "deviationFromMean",
          customRender: (text, record) =>
            ["整体"].includes(record.schoolName)
              ? "-"
              : record.deviationFromMean,
        },
        {
          title: "超均率（整体均值）",
          align: "center",
          dataIndex: "overAverageRate",
          customRender: (text, record) =>
            record.schoolName === "整体"
              ? "-"
              : (record.overAverageRate || 0) + "%",
        },
        {
          title: "标准差",
          align: "center",
          dataIndex: "standardDeviation",
        },
        {
          title: "差异系数",
          align: "center",
          dataIndex: "coefficientOfVariation",
          customRender: (text, record) => record.coefficientOfVariation + "%",
        },
      ];
      this.tableData =
        this.topData.isSchoolDisplayReportUnionAverageScore == 1
          ? res.data.data
          : res.data.data.filter((item) => item.schoolName != "整体");
      this.typeChange();
    },
    rowClassName(record) {
      if (record.schoolName === "本校") {
        return "currentSchool";
      }
    },
    typeChange() {
      const enmu = {
        1: this.getAvg,
        2: this.getOnline,
        3: this.getRatio,
        4: this.getTopSection,
        5: this.getOther,
      };
      if (this.type == 2) {
        this.selectOptions = this.onlines.map((item) => ({
          label: item.name,
          value: item.name,
          ...item,
        }));
        if (this.selectOptions.length) {
          this.value = this.selectOptions[0].value;
        }
      } else if (this.type == 3) {
        this.selectOptions = this.ratios.map((item) => ({
          label: item.name,
          value: item.name,
          ...item,
        }));
        if (this.selectOptions.length) {
          this.value = this.selectOptions[0].value;
        }
      } else if (this.type == 4) {
        this.selectOptions = this.topSections.map((item) => ({
          label: item.name,
          value: item.name,
          ...item,
        }));
        if (this.selectOptions.length) {
          this.value = this.selectOptions[0].value;
        }
      } else if (this.type == 5) {
        this.selectOptions = [
          {
            label: "标准差",
            value: "standardDeviation",
          },
          {
            label: "超均率",
            value: "overAverageRate",
          },
          {
            label: "最高分",
            value: "highestScore",
          },
          {
            label: "最低分",
            value: "lowestScore",
          },
        ];
        this.value = "standardDeviation";
      }
      enmu[this.type]();
    },
    handleChange() {
      const enmu = {
        1: this.getAvg,
        2: this.getOnline,
        3: this.getRatio,
        4: this.getTopSection,
        5: this.getOther,
      };
      enmu[this.type]();
    },
    getAvg() {
      let notTotal = this.tableData.filter(
        (item) => item.schoolName !== "整体"
      );
      let total = this.tableData.find((item) => item.schoolName === "整体");
      let data = {
        xAxis: notTotal.map((item) => item.schoolName),
        barData: notTotal.map((item) => item.averageScore),
        lineData:
          this.topData.isSchoolDisplayReportUnionAverageScore == 1
            ? notTotal.map(() => total.averageScore)
            : [],
      };
      this.initCharts(data);
    },
    getOnline() {
      let notTotal = this.tableData.filter(
        (item) => item.schoolName !== "整体"
      );
      let total = this.tableData.find((item) => item.schoolName === "整体");
      let data = {
        xAxis: notTotal.map((item) => item.schoolName),
        barData: notTotal.map(
          (item) => item.onlines.find((it) => it.name === this.value).proportion
        ),
        lineData:
          this.topData.isSchoolDisplayReportUnionAverageScore == 1
            ? notTotal.map(
                () =>
                  total.onlines.find((it) => it.name === this.value).proportion
              )
            : [],
      };
      this.initCharts(data);
    },

    getRatio() {
      let notTotal = this.tableData.filter(
        (item) => item.schoolName !== "整体"
      );
      let total = this.tableData.find((item) => item.schoolName === "整体");
      let data = {
        xAxis: notTotal.map((item) => item.schoolName),
        barData: notTotal.map(
          (item) => item.ratios.find((it) => it.name === this.value).proportion
        ),
        lineData:
          this.topData.isSchoolDisplayReportUnionAverageScore == 1
            ? notTotal.map(
                () =>
                  total.ratios.find((it) => it.name === this.value).proportion
              )
            : [],
      };
      this.initCharts(data);
    },
    getTopSection() {
      let notTotal = this.tableData.filter(
        (item) => item.schoolName !== "整体"
      );
      let total = this.tableData.find((item) => item.schoolName === "整体");
      let data = {
        xAxis: notTotal.map((item) => item.schoolName),
        barData: notTotal.map(
          (item) =>
            item.topSections.find((it) => it.name === this.value).proportion
        ),
        lineData:
          this.topData.isSchoolDisplayReportUnionAverageScore == 1
            ? notTotal.map(
                () =>
                  total.topSections.find((it) => it.name === this.value)
                    .proportion
              )
            : [],
      };
      this.initCharts(data);
    },
    getOther() {
      let notTotal = this.tableData.filter(
        (item) => item.schoolName !== "整体"
      );
      let total = this.tableData.find((item) => item.schoolName === "整体");
      let data = {
        xAxis: notTotal.map((item) => item.schoolName),
        barData: notTotal.map((item) => item[this.value]),
        lineData:
          this.topData.isSchoolDisplayReportUnionAverageScore == 1
            ? notTotal.map(() => total[this.value])
            : [],
      };
      this.initCharts(data);
    },
    initCharts({ xAxis, barData, lineData }) {
      let series = [
        {
          name: "学校",
          type: "bar",
          data: [...barData],
          barMaxWidth: 40,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: "top",
                textStyle: {
                  color: "inherit",
                  fontSize: 9,
                },
              },
            },
          },
        },
      ];
      if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        series.push({
          name: "整体",
          type: "line",
          smooth: true,
          data: [...lineData],
        });
      }
      let options = {
        grid: {
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        dataZoom: [
          //滚动条
          {
            show: xAxis.length > 5,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: xAxis.length - (xAxis.length - 5),
            xAxisIndex: [0],
            bottom: "3%",
            left: "center",
          },
        ],
        legend: {},
        color: ["#2474ED", "#FF743A"],
        xAxis: [
          {
            type: "category",
            data: [...xAxis],
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: {
          type: "value",
        },
        series: [...series],
      };

      var chart = echarts.init(this.$refs.chart);
      chart.setOption(options);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../union.scss";
.filter {
  display: flex;
  justify-content: flex-end;
  margin: 18px 0;
}
.chart {
  width: 100%;
  height: 380px;
}
.select {
  text-align: right;
}
::v-deep .currentSchool {
  color: #f57574 !important;
  background-color: #fff9f9 !important;
  td {
    color: #f57574 !important;
    background-color: #fff9f9 !important;
  }
}
</style>
