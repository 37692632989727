<template>
  <div
    ref="pageNav"
    class="pageNav"
    :style="{ left: left + 'px' }"
    @mousedown="navMouseDown"
  >
    <div v-if="show" class="list">
      <div
        v-for="(item, index) in menuList"
        :key="index"
        :class="subIndex === index ? 'active' : 'list-item'"
        @click="handleClick(item, index)"
      >
        {{ item.name }}
      </div>
      <div class="list-item" @click="show = false">收起</div>
    </div>
    <div v-if="!show" class="shrink">
      <div class="title">页 面 导 航</div>
      <div class="extend" @click="show = true">展开</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNav",
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: true,
      subIndex: 0,
      left: 0,
    };
  },
  mounted() {
    this.onresize();
    window.addEventListener("scroll", this.onscroll);
    window.addEventListener("resize", this.onresize);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onscroll);
    window.removeEventListener("resize", this.onresize);
  },
  methods: {
    onresize() {
      if (window.innerWidth <= 1280) {
        this.left = 28;
      } else {
        this.left = (window.innerWidth - 1200) / 2 - 125 - 28;
      }
    },
    onscroll() {
      this.menuList.forEach((item, index) => {
        let anchor = document.getElementById(item.id);
        let ws = document.documentElement.scrollTop || document.body.scrollTop;
        if (anchor) {
          if (ws > anchor.offsetTop - 200) {
            this.subIndex = index;
          }
        }
      });
    },
    navMouseDown(e) {
      e.preventDefault();
      let el = this.$refs.pageNav;
      let disx = e.clientX - el.offsetLeft;
      let disy = e.clientY - el.offsetTop;
      el.style.cursor = "grabbing";
      document.onmousemove = function (event) {
        el.style.left = `${event.clientX - disx}px`;
        el.style.top = `${event.clientY - disy}px`;
      };
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
        el.style.cursor = "default";
        return false;
      };
    },
    handleClick(item) {
      let anchor = document.getElementById(item.id);
      window.scrollTo({
        top: anchor.offsetTop - 100,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.pageNav {
  position: fixed;
  top: 90px;
  z-index: 99;
  .list {
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    div {
      height: 40px;
      color: #8c8c8c;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #f1f3f5;
      font-size: 13px;
      color: #666;
      padding: 0 10px;
      &:hover {
        color: #fff;
        background-color: #2474ed;
      }
    }
    .active {
      color: #fff;
      background-color: #2474ed;
    }
  }
  .shrink {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 167px;
    border-radius: 10px;
    flex-direction: column;
    background-color: #fff;
    color: #666;
    .title {
      flex: 1;
      white-space: pre-wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 0 14px;
    }
    .extend {
      box-sizing: border-box;
      padding: 11px 14px;
      white-space: pre-wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #d8d8d8;
      cursor: pointer;
    }
  }
}
</style>
