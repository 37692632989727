<template>
  <a-popover placement="bottom" trigger="hover">
    <template slot="content">
      <template v-if="subjectList.length">
        <div class="operate">
          <a-button type="link" @click="allCheck">全选</a-button>
          <a-button type="link" @click="reverse">反选</a-button>
        </div>
        <a-checkbox-group
          v-model="checkList"
          name="checkboxgroup"
          @change="onChange"
        >
          <div class="checkbox-group">
            <a-checkbox
              v-for="(item, index) in subjectList"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-checkbox>
          </div>
        </a-checkbox-group>
      </template>
      <no-data v-else message="暂无数据"></no-data>
    </template>
    <a-select
      v-model="subject"
      not-found-content="暂无数据"
      style="width: 150px"
      :placeholder="placeholder"
      :open="false"
    >
    </a-select>
  </a-popover>
</template>

<script>
export default {
  name: "SubjectList",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    subjectList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkList: [],
      subject: undefined,
    };
  },
  watch: {
    subjectList: {
      deep: true,
      handler(val) {
        this.checkList = [...val.map((item) => item.id)];
        this.$emit("change", this.checkList);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkList = [...this.subjectList.map((item) => item.id)];
      this.$emit("change", this.checkList);
    });
  },
  methods: {
    allCheck() {
      this.checkList = this.subjectList.map((item) => item.id);
      this.$emit("change", this.checkList);
    },
    reverse() {
      let data = this.subjectList.filter(
        (item) => !this.checkList.includes(item.id)
      );
      this.checkList = [...data];

      this.$emit("change", this.checkList);
    },
    onChange() {
      this.$emit("change", this.checkList);
    },
  },
};
</script>
<style lang="scss" scoped>
.operate {
  margin-bottom: 18px;
}
.checkbox-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.ant-checkbox-wrapper {
  margin-bottom: 8px;
  margin-right: 8px;
}
</style>
