<template>
  <div v-loading="loading">
    <div class="sub-title">各班上线情况</div>

    <a-table
      :pagination="false"
      :columns="columns"
      :data-source="tableData"
      style="margin-bottom: 24px"
      bordered
      :scroll="{ x: 'max-content' }"
    ></a-table>
    <div v-if="onlines.length" class="dataType">
      <a-select
        v-model="dataType"
        style="width: 150px"
        allow-clear
        @change="onlinesChange"
      >
        <a-select-option
          v-for="(item, index) in onlines"
          :key="index"
          :value="item.name"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </div>
    <template v-if="onlines.length">
      <div ref="chart" class="chart"></div>
    </template>
    <no-data v-else></no-data>
  </div>
</template>

<script>
import { getstatclassoption } from "@/core/api/academic/common";
import { getunionreportclasscompetitiveclassonline } from "@/core/api/academic/union";
import * as echarts from "echarts";
export default {
  name: "",
  components: {},
  props: {
    subjectId: {
      default: "",
      type: [Number, String],
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      classNumList: [],
      tableData: [],
      dataType: null,
      selectOptions: [],
      columns: [],
      classNum: [],
      onlines: [],
      loading: false,
    };
  },
  mounted() {
    this.getClassNums();
  },
  methods: {
    async getData() {
      this.loading = true;
      const res = await getunionreportclasscompetitiveclassonline({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
        subjectId: this.subjectId,
        classNums: this.classNum.length ? this.classNum.join(",") : null,
      });
      if (this.topData.isSchoolDisplayReportUnionAverageScore) {
        this.tableData = res.data.data;
      } else {
        this.tableData = res.data.data.filter(
          (item) => item.classNum != "整体"
        );
      }
      this.onlines = [];
      if (res.data.data.length) {
        this.onlines = res.data.data[0].onlines;
        if (this.onlines.length) {
          this.dataType = this.onlines[0].name;
        }
      }
      this.loading = false;
      this.columns = [
        {
          title: "班级",
          align: "center",
          dataIndex: "classNum",
        },
        {
          title: this.subjectId === 0 ? "班主任" : "任课教师",
          align: "center",
          dataIndex: "teacherName",
          customRender: (text, record) =>
            ["本校", "整体高位班级", "整体"].includes(record.classNum)
              ? "-"
              : record.teacherName,
        },
        {
          title: "参统考生",
          align: "center",
          dataIndex: "statTotal",
        },
        {
          title: "平均分",
          align: "center",
          dataIndex: "averageScore",
        },
        ...this.onlines.map((item, index) => ({
          title: item.name,
          align: "center",
          dataIndex: "onlines" + index,
          children: [...this.hasFieldCols(index)],
        })),
      ];
      this.initCharts();
    },
    hasFieldCols(index) {
      let cols = [
        {
          title: "人数",
          align: "center",
          dataIndex: "total" + index,
          customRender: (text, record) => record.onlines[index].total,
        },
        {
          title: "占比",
          align: "center",
          dataIndex: "proportion" + index,
          customRender: (text, record) =>
            record.onlines[index].proportion + "%",
        },
      ];
      if (this.topData.isSchoolDisplayReportClassRank == 1) {
        cols.push({
          title: "排名",
          align: "center",
          dataIndex: "rank" + index,
          customRender: (text, record) =>
            ["本校", "整体"].includes(record.classNum)
              ? "-"
              : record.onlines[index].rank,
        });
      }
      return cols;
    },
    onlinesChange() {
      this.initCharts();
    },
    classNumChange(val) {
      this.classNum = val;
      this.getData();
    },
    async getClassNums() {
      const res = await getstatclassoption({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.classNum = res.data.data.map((item) => item.name);

      this.getData();
    },
    initCharts() {
      let schoolData = this.tableData.find((item) => item.classNum === "本校");
      let totalData = this.tableData.find((item) => item.classNum === "整体");
      let classData = this.tableData.filter(
        (item) => !["本校", "整体"].includes(item.classNum)
      );
      let series = [
        {
          name: this.dataType,
          type: "bar",
          data: [
            ...classData.map(
              (item) =>
                item.onlines.find((it) => it.name === this.dataType).proportion
            ),
          ],
          barMaxWidth: 40,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: "top",
                textStyle: {
                  color: "inherit",
                  fontSize: 9,
                },
              },
            },
          },
        },
        {
          name: "校均值",
          type: "line",
          smooth: true,
          data: [
            ...classData.map(
              () =>
                schoolData.onlines.find((it) => it.name === this.dataType)
                  .proportion
            ),
          ],
        },
      ];
      if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        series.push({
          name: "整体",
          type: "line",
          smooth: true,
          data: [
            ...classData.map(
              () =>
                totalData.onlines.find((it) => it.name === this.dataType)
                  .proportion
            ),
          ],
        });
      }
      let options = {
        grid: {
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        dataZoom: [
          //滚动条
          {
            show: classData.length > 5,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: classData.length - (classData.length - 5),
            xAxisIndex: [0],
            bottom: "3%",
            left: "center",
          },
        ],
        legend: {},
        color: ["#2474ED", "#5AD8A6", "#FF8857"],
        xAxis: [
          {
            type: "category",
            data: [...classData.map((item) => item.classNum)],
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: {
          type: "value",
        },
        series: [...series],
      };

      setTimeout(() => {
        var chart = echarts.init(this.$refs.chart);
        chart.setOption(options);
      }, 500);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../union.scss";
.filter {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.dataType {
  margin-bottom: 24px;
}
.chart {
  width: 100%;
  height: 380px;
}
</style>
