<template>
  <div class="container">
    <div class="title">学科竞争力</div>
    <div class="subjectList">
      <div
        v-for="(item, index) in subjectList"
        :key="index"
        :class="['subject', subjectId === item.id ? 'active' : '']"
        @click="handleClick(item, index)"
      >
        {{ item.name }}
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="false"
      :scroll="{ x: 'max-content' }"
    ></a-table>
    <div class="filter">
      <a-radio-group v-model="type" button-style="solid" @change="typeChange">
        <a-radio-button value="1"> 平均分 </a-radio-button>
        <a-radio-button v-if="onlines.length" value="2">
          上线情况
        </a-radio-button>
        <a-radio-button v-if="ratios.length" value="3"> 比 率 </a-radio-button>
        <a-radio-button v-if="topSections.length" value="4">
          前N名
        </a-radio-button>
        <a-radio-button v-if="subjectId != 0" value="5">
          其他指标
        </a-radio-button>
      </a-radio-group>
    </div>
    <div class="select">
      <a-select
        v-if="type != 1"
        v-model="value"
        style="width: 150px"
        not-found-content="暂无数据"
        placeholder="请选择"
        @change="handleChange"
      >
        <a-select-option
          v-for="(item, index) in selectOptions"
          :key="index"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <div ref="chart" class="chart"></div>
    <div v-if="['2', '3', '4'].includes(type) && subjectId === 0" class="tip">
      <template v-if="type == '2'">
        注：总分下上线率指参考的全部学科达到指定上线分数的人数在本单位占比
      </template>
      <template v-if="type == '3'">
        注：总分下比率指参考的全部学科达到指定比率的人数在本单位占比
      </template>
      <template v-if="type == '4'">
        注：总分下比率指参考的全部学科达到指定位次的人数在本单位占比
      </template>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getstatsubjectoption } from "@/core/api/academic/common";
import { getunionreportsubjectcompetitive } from "@/core/api/academic/union";
export default {
  name: "",
  props: {
    topData: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      chartKey: Math.random(),
      type: "1",
      subjectId: null,
      columns: [
        {
          title: "学校",
          align: "center",
          dataIndex: "schoolName",
          fixed: true,
        },
        {
          title: "本校参统考生",
          align: "center",
          dataIndex: "statTotal",
          fixed: true,
        },
      ],
      tableData: [],
      selectOptions: [],
      subjectList: [],
      value: null,
      averageScores: [],
      onlines: [],
      ratios: [],
      topSections: [],
    };
  },
  mounted() {
    this.getSubjectList();
  },
  methods: {
    handleClick(item) {
      this.subjectId = item.id;
      this.getData();
    },
    hasAvgCols(res) {
      let cols = [
        {
          title: "分值（与整体差值）",
          dataIndex: "averageScore",
          align: "center",
          customRender: (text, record) => {
            let currentSchool = res.data.data.find(
              (item) => item.schoolName === "整体"
            );
            let diff = currentSchool
              ? (record.averageScore - currentSchool.averageScore).toFixed(2)
              : "-";
            if (diff > 0) {
              return (
                <span>
                  {record.averageScore}
                  <span style="color:#F57574">+{diff}</span>{" "}
                </span>
              );
            } else if (diff < 0) {
              return (
                <span>
                  {record.averageScore}
                  <span style="color:#2474ED">{diff}</span>{" "}
                </span>
              );
            } else {
              return <span>{record.averageScore}</span>;
            }
          },
        },
      ];
      if (this.topData.isSchoolDisplayReportSchoolRank == 1) {
        cols.push({
          title: "排名",
          dataIndex: "averageScoreRank ",
          align: "center",
          customRender: (text, record) =>
            record.schoolName === "整体" ? "-" : record.averageScoreRank,
        });
      }
      return cols;
    },
    hasFieldsCols(field, index) {
      let cols = [
        {
          title: "上线人数 ",
          align: "center",
          dataIndex: field + "-total-" + index,
          customRender: (text, record) => record[field][index].total,
        },
        {
          title: "占比（%）",
          align: "center",
          dataIndex: field + "-proportion-" + index,
          customRender: (text, record) => record[field][index].proportion + "%",
        },
      ];
      if (this.topData.isSchoolDisplayReportSchoolRank == 1) {
        cols.push({
          title: "排名",
          align: "center",
          dataIndex: field + "-rank-" + index,
          customRender: (text, record) =>
            record.schoolName === "整体" ? "-" : record[field][index].rank,
        });
      }
      return cols;
    },
    async getData() {
      const res = await getunionreportsubjectcompetitive({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
        subjectId: this.subjectId,
      });
      this.tableData = res.data.data;
      if (res.data.data.length) {
        if (this.subjectId === 0) {
          this.averageScores =
            this.topData.isSchoolDisplayReportUnionAverageScore == 1
              ? res.data.data[0].averageScores
              : res.data.data[0].averageScores.filter(
                  (item) => item.schoolName != "整体"
                );
          this.onlines =
            this.topData.isSchoolDisplayReportUnionAverageScore == 1
              ? res.data.data[0].onlines
              : res.data.data[0].onlines.filter(
                  (item) => item.schoolName != "整体"
                );
          this.ratios =
            this.topData.isSchoolDisplayReportUnionAverageScore == 1
              ? res.data.data[0].ratios
              : res.data.data[0].ratios.filter(
                  (item) => item.schoolName != "整体"
                );
          this.topSections =
            this.topData.isSchoolDisplayReportUnionAverageScore == 1
              ? res.data.data[0].topSections
              : res.data.data[0].topSections.filter(
                  (item) => item.schoolName != "整体"
                );
        } else {
          this.averageScores = res.data.data[0].averageScores;
          this.onlines = res.data.data[0].onlines;
          this.ratios = res.data.data[0].ratios;
          this.topSections = res.data.data[0].topSections;
        }
      }
      this.typeChange();
      if (this.subjectId === 0) {
        this.columns = [
          {
            dataIndex: "subjectName",
            title: "学科",
            align: "center",
            fixed: true,
          },
          {
            dataIndex: "statTotal",
            title: "本校参统考生",
            align: "center",
            fixed: true,
          },
          {
            dataIndex: "averageScores",
            title: "平均分",
            align: "center",
            children: [
              ...this.averageScores.map((item, index) => ({
                title: item.schoolName,
                dataIndex: "averageScores" + index,
                align: "center",
                customRender: (text, record) => {
                  if (record.averageScores.length) {
                    let itemData = record.averageScores.find(
                      (it) => it.schoolName == item.schoolName
                    );
                    return itemData.value;
                  }
                  return "";
                },
              })),
            ],
          },
          ...this.onlines.map((item, index) => ({
            dataIndex: "onlines" + index,
            title: item.settingName,
            align: "center",
            children: [
              ...item.schools.map((it, ind) => ({
                title: it.schoolName,
                dataIndex: "schools-onlines-" + index + ind,
                align: "center",
                customRender: (text, record) =>
                  record.onlines.length
                    ? record.onlines[index].schools[ind].value
                    : "",
              })),
            ],
          })),
          ...this.ratios.map((item, index) => ({
            dataIndex: "ratios" + index,
            title: item.settingName,
            align: "center",
            children: [
              ...item.schools.map((it, ind) => ({
                title: it.schoolName,
                dataIndex: "schools-ratios-" + index + ind,
                align: "center",
                customRender: (text, record) =>
                  record.ratios.length
                    ? record.ratios[index].schools[ind].value
                    : "",
              })),
            ],
          })),
          ...this.topSections.map((item, index) => ({
            dataIndex: "topSections" + index,
            title: item.settingName,
            align: "center",
            children: [
              ...item.schools.map((it, ind) => ({
                title: it.schoolName,
                dataIndex: "schools-topSections-" + index + ind,
                align: "center",
                customRender: (text, record) =>
                  record.topSections.length
                    ? record.topSections[index].schools[ind].value
                    : "",
              })),
            ],
          })),
        ];
      } else {
        this.columns = [
          {
            title: "学校",
            dataIndex: "schoolName",
            align: "center",
          },
          {
            title: "参统考生",
            dataIndex: "statTotal",
            align: "center",
          },
          {
            dataIndex: "averageScores",
            title: "平均分",
            align: "center",
            children: [...this.hasAvgCols(res)],
          },
          ...this.onlines.map((item, index) => ({
            title: item.name,
            align: "center",
            dataIndex: "onlines" + index,
            children: [...this.hasFieldsCols("onlines", index)],
          })),
          ...this.ratios.map((item, index) => ({
            title: item.name,
            align: "center",
            dataIndex: "ratios" + index,
            children: [...this.hasFieldsCols("ratios", index)],
          })),
          ...this.topSections.map((item, index) => ({
            title: item.name,
            align: "center",
            dataIndex: "topSections" + index,
            children: [...this.hasFieldsCols("topSections", index)],
          })),
          {
            title: "最高分",
            align: "center",
            dataIndex: "highestScore",
          },
          {
            title: "最低分",
            align: "center",
            dataIndex: "lowestScore",
          },
          {
            title: "中位数",
            align: "center",
            dataIndex: "median",
          },
          {
            title: "全距",
            align: "center",
            dataIndex: "rangeScore",
          },
          {
            title: "离均差（整体均值）",
            align: "center",
            dataIndex: "deviationFromMean",
            customRender: (text, record) =>
              record.schoolName === "整体"
                ? "-"
                : record.deviationFromMean || 0,
          },
          {
            title: "超均率（整体均值）",
            align: "center",
            dataIndex: "overAverageRate",
            customRender: (text, record) =>
              record.schoolName === "整体" ? "-" : record.overAverageRate || 0,
          },
          {
            title: "标准差",
            align: "center",
            dataIndex: "standardDeviation",
          },
          {
            title: "差异系数",
            align: "center",
            dataIndex: "coefficientOfVariation",
            customRender: (text, record) => record.coefficientOfVariation + "%",
          },
        ];
      }
    },
    async getSubjectList() {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        hasTotal: 1,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.subjectList = res.data.data;
      if (res.data.data.length) {
        this.subjectId = res.data.data[0].id;
        this.getData();
      }
    },
    typeChange() {
      const enmu = {
        1: this.getAvg,
        2: this.setChart,
        3: this.setChart,
        4: this.setChart,
      };
      const keyEnmu = {
        2: "onlines",
        3: "ratios",
        4: "topSections",
      };
      if (["2", "3", "4"].includes(this.type)) {
        if (this.tableData.length) {
          if (this.subjectId === 0) {
            this.selectOptions = this.tableData[0][keyEnmu[this.type]].map(
              (item) => ({
                label: item.settingName,
                value: item.settingName,
              })
            );
            this.value = this.selectOptions[0].value;
          } else {
            this.selectOptions = this.tableData[0][keyEnmu[this.type]].map(
              (item) => ({
                label: item.name,
                value: item.name,
              })
            );
            this.value = this.selectOptions[0].value;
          }
        }
        enmu[this.type]();
      } else if (this.type == 5) {
        this.selectOptions = [
          {
            label: "中位数",
            value: "median",
          },
          {
            label: "超均率",
            value: "overAverageRate",
          },
          {
            label: "最高分",
            value: "highestScore",
          },
          {
            label: "最低分",
            value: "lowestScore",
          },
        ];
        this.value = "median";
        this.setOther();
      } else {
        enmu[this.type]();
      }
    },
    handleChange() {
      const enmu = {
        1: this.getAvg,
        2: this.setChart,
        3: this.setChart,
        4: this.setChart,
        5: this.setOther,
      };
      enmu[this.type]();
    },
    getAvg() {
      if (this.subjectId === 0) {
        let averageScores = [];
        if (this.tableData.length) {
          let arr = this.tableData[0].averageScores.filter(
            (item) => item.schoolName !== "本校"
          );
          if (this.topData.isSchoolDisplayReportUnionAverageScore != 1) {
            averageScores = arr.filter((item) => item.schoolName !== "整体");
          } else {
            averageScores = [...arr];
          }
        }
        let data = {
          xAxis: this.tableData.map((item) => item.subjectName),
          series: [
            {
              name: "本校",
              type: "bar",
              data: [
                ...this.tableData.map(
                  (item) =>
                    item.averageScores.find((it) => it.schoolName === "本校")
                      .value
                ),
              ],
              barMaxWidth: 40,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    position: "top",
                    textStyle: {
                      color: "inherit",
                      fontSize: 9,
                    },
                  },
                },
              },
            },
            ...averageScores.map((item) => {
              let lineData = this.tableData.map((it) => {
                let avg = it.averageScores.find(
                  (i) => i.schoolName === item.schoolName
                );
                return avg.value;
              });
              return {
                name: item.schoolName,
                type: "line",
                smooth: true,
                data: [...lineData],
              };
            }),
          ],
        };
        this.initCharts(data);
      } else {
        let notTotal = this.tableData.filter(
          (item) => item.schoolName !== "整体"
        );
        let total = this.tableData.find((item) => item.schoolName === "整体");
        let series = [
          {
            name: "学校",
            type: "bar",
            data: [...notTotal.map((item) => item.averageScore)],
            barMaxWidth: 40,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "inherit",
                    fontSize: 9,
                  },
                },
              },
            },
          },
        ];
        if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          series.push({
            name: "整体",
            type: "line",
            smooth: true,
            data: [...notTotal.map(() => total.averageScore)],
          });
        }
        let data = {
          xAxis: notTotal.map((item) => item.schoolName),
          series: [...series],
        };
        this.initCharts(data);
      }
    },
    setChart() {
      const keyEnmu = {
        2: "onlines",
        3: "ratios",
        4: "topSections",
      };
      let key = keyEnmu[this.type];
      if (this.subjectId === 0) {
        let schools = [];
        let obj = this.tableData.find((item) => item.subjectId === 0)[key];
        let dataSource = obj.find((item) => item.settingName === this.value);
        if (dataSource && dataSource.schools.length) {
          schools = dataSource.schools;
        }
        let total = dataSource.schools.find(
          (item) => item.schoolName === "整体"
        );
        let notTotal = dataSource.schools.filter(
          (item) => item.schoolName !== "整体"
        );
        let series = [
          {
            name: "学校",
            type: "bar",
            data: [...notTotal.map((item) => item.value)],
            barMaxWidth: 40,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "inherit",
                    fontSize: 9,
                  },
                },
              },
            },
          },
        ];

        if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          series.push({
            name: "整体",
            type: "line",
            smooth: true,
            data: [...notTotal.map(() => total.value)],
          });
        }
        let data = {
          xAxis: schools
            .map((item) => item.schoolName)
            .filter((item) => item != "整体"),
          series: [...series],
        };
        this.initCharts(data);
      } else {
        let total = this.tableData.find((item) => item.schoolName === "整体");
        let notTotal = this.tableData.filter(
          (item) => item.schoolName !== "整体"
        );
        let schoolData = notTotal.map((item) => {
          let obj = item[key].find((it) => it.name === this.value);
          return obj.proportion;
        });
        let totlaData = total[key].find(
          (item) => item.name === this.value
        ).proportion;
        let series = [
          {
            name: "学校",
            type: "bar",
            data: [...schoolData],
            barMaxWidth: 40,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "inherit",
                    fontSize: 9,
                  },
                },
              },
            },
          },
        ];
        if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          series.push({
            name: "整体",
            type: "line",
            smooth: true,
            data: [...notTotal.map(() => totlaData)],
          });
        }
        let data = {
          xAxis: this.tableData
            .map((item) => item.schoolName)
            .filter((item) => item !== "整体"),
          series: [...series],
        };
        this.initCharts(data);
      }
    },
    setOther() {
      let total = this.tableData.find((item) => item.schoolName === "整体");
      let notTotal = this.tableData.filter(
        (item) => item.schoolName !== "整体"
      );
      let schoolData = notTotal.map((item) => item[this.value]);
      let totlaData = total[this.value];
      let series = [
        {
          name: "学校",
          type: "bar",
          data: [...schoolData],
          barMaxWidth: 40,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: "top",
                textStyle: {
                  color: "inherit",
                  fontSize: 9,
                },
              },
            },
          },
        },
      ];
      if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        series.push({
          name: "整体",
          type: "line",
          smooth: true,
          data: [...notTotal.map(() => totlaData)],
        });
      }
      let data = {
        xAxis: this.tableData
          .map((item) => item.schoolName)
          .filter((item) => item !== "整体"),
        series: [...series],
      };

      this.initCharts(data);
    },
    initCharts({ xAxis, series }) {
      let options = {
        grid: {
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        dataZoom: [
          //滚动条
          {
            show: xAxis.length > 5,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: xAxis.length - (xAxis.length - 5),
            xAxisIndex: [0],
            bottom: "3%",
            left: "center",
          },
        ],
        legend: {},
        color: ["#2474ED", "#5AD8A6", "#5D7092", "#F6BD16", "#E8684A"],
        xAxis: [
          {
            type: "category",
            data: [...xAxis],
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: {
          type: "value",
        },
        series: [...series],
      };

      this.$nextTick(() => {
        this.$refs.chart.removeAttribute("_echarts_instance_");
        let chart = echarts.init(this.$refs.chart);
        chart.setOption(options);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../union.scss";
.filter {
  display: flex;
  justify-content: flex-end;
  margin: 18px 0;
}
.chart {
  width: 100%;
  height: 380px;
}
.subjectList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  .subject {
    height: 32px;
    border-radius: 4px;
    border: 1px solid #d5d6db;
    box-sizing: border-box;
    padding: 0 18px;
    line-height: 32px;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 18px;
    cursor: pointer;
    &:hover {
      background-color: #2474ed;
      color: #fff;
      border-color: #2474ed;
    }
  }
  .active {
    background-color: #2474ed;
    color: #fff;
    border-color: #2474ed;
  }
}
.select {
  text-align: right;
}
</style>
