<template>
  <div class="stat-contain">
    <div class="topData">
      <div class="school-tag">{{ $route.query.schoolName }}</div>
      <div class="title">
        <div class="text">{{ topData.name }}</div>
        <div :class="['sub-tag', 'general']">
          {{ getExamType(topData.type) }}
        </div>
      </div>
      <div class="sub">
        <div class="back" @click="back"><a-icon type="left" /> 返回</div>
        <div class="date">
          <span>年级：{{ topData.year }}级</span>
          <span>考试时间：{{ topData.examDate }}</span>
        </div>
        <div></div>
      </div>
      <div class="model-title">联考报告</div>
      <div class="dashboard">
        <div class="tag">
          <div class="value">
            <span>{{ topData.statTotal }}</span
            >个
          </div>
          <div class="label">参统考生</div>
        </div>
        <div v-if="topData.isSchoolDisplayReportSchoolRank == 1" class="tag">
          <div class="value">
            <span>{{ topData.rank }}</span>
          </div>
          <div class="label">考试排名</div>
        </div>
        <div class="tag warning">
          <div class="value">
            <span>{{ topData.averageScore }}</span>
          </div>
          <div class="label">校平均分</div>
        </div>
        <div class="tag warning">
          <div class="value">
            <span>{{ topData.highestScore }}</span>
          </div>
          <div class="label">校最高分</div>
        </div>
        <div class="tag success">
          <div class="value">
            <span>{{ topData.overAverageRate }}%</span>
          </div>
          <div class="label">超均率</div>
        </div>
        <div class="flex">
          <div
            v-for="(item, index) in topData.topInfoOnlineVOs"
            :key="'topInfoOnlineVOs' + index"
            class="item"
          >
            <div class="value">
              <span>{{ item.value + "%" || "-" }}</span>
            </div>
            <div class="label">{{ item.name }}</div>
          </div>
          <div
            v-for="(item, index) in topData.topInfoRatioVOs"
            :key="'topInfoRatioVOs' + index"
            class="item"
          >
            <div class="value">
              <span>{{ item.value + "%" || "-" }}</span>
            </div>
            <div class="label">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="topData">
      <competitiveness id="competitiveness" :top-data="topData" />
      <subject id="subject" :top-data="topData" />
      <class-competitive
        v-if="topData.statClass == 1"
        id="classCompetitive"
        :top-data="topData"
      />
      <high-score
        v-if="topData.statClass == 1"
        id="highScore"
        :top-data="topData"
      />
      <development id="development" :top-data="topData" />
    </template>
    <page-nav :menu-list="menuList" />
  </div>
</template>

<script>
import { examTypeOptions } from "@/core/util/constdata";
import competitiveness from "./components/competitiveness.vue";
import classCompetitive from "./components/class.vue";
import pageNav from "@/views/academic/components/pageNav.vue";
import development from "./components/development.vue";
import highScore from "./components/highScore.vue";
import subject from "./components/subject.vue";
import { getunionreporttopinfo } from "@/core/api/academic/union";
export default {
  name: "",
  components: {
    subject,
    competitiveness,
    classCompetitive,
    highScore,
    development,
    pageNav,
  },
  data() {
    return {
      menuList: [
        {
          name: "整体竞争力",
          id: "competitiveness",
        },
        {
          name: "学科竞争力",
          id: "subject",
        },
        {
          name: "班级竞争力",
          id: "classCompetitive",
        },
        {
          name: "高分群体",
          id: "highScore",
        },
        {
          name: "发展性分析",
          id: "development",
        },
      ],
      schoolName: "",
      topData: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await getunionreporttopinfo({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.topData = res.data.data;
      if (this.topData.statClass != 1) {
        this.menuList = this.menuList.filter(
          (item) => !["班级竞争力", "高分群体"].includes(item.name)
        );
      }
    },
    back() {
      this.$router.go(-1);
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./union.scss";
.stat-contain {
  .dashboard {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .tag {
    box-sizing: border-box;
    padding: 18px 24px;
    border-radius: 8px;
    text-align: center;
    background: #f3f8ff;
    span {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1px;
    }
    .value {
      color: #2474ed;
    }
    .label {
      margin-top: 8px;
    }
    color: #3e4551;
    font-size: 12px;
  }
  .warning {
    background: #fff8f3;
    .value {
      color: #ff7a05;
    }
  }
  .tag + .tag {
    margin-left: 18px;
  }
  .success {
    background-color: #f3fcf8;
    .value {
      color: #2bb472;
    }
  }
  .tag + .flex {
    margin-left: 18px;
  }
  .flex {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    text-align: center;
    padding: 18px 40px;
    border-radius: 8px;
    background: #f3fcf8;
    .item {
      span {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 1px;
      }
      .value {
        color: #2bb472;
      }
      .label {
        margin-top: 8px;
      }
      color: #3e4551;
      font-size: 12px;
    }
  }
}
</style>
