<template>
  <div class="container">
    <div class="title">班级竞争力</div>
    <div class="filter">
      <div class="sub-title">学科均衡分析</div>
      <a-space>
        <classNums
          ref="classNums"
          style="margin-right: 8px"
          :placeholder="'选择班级'"
          :class-num-list="classNumList"
          @change="classNumChange"
        />
        <subjectCheck
          ref="classNums"
          style="margin-right: 8px"
          :placeholder="'选择科目'"
          :subject-list="checkSubject"
          @change="subjectChange"
        />
      </a-space>
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      style="margin-bottom: 18px"
      bordered
      :pagination="false"
      :scroll="{ x: 'max-content' }"
    ></a-table>
    <div class="tip">
      此图按各班级总分平均分降序排列，蓝色代表班级指标值相对较大，红色代表班级指标值相对较小，横向查看同一班级不同学科情况，纵向比较同一学科不同班级情况，如出现色块跳跃则该班级该科值得关注。
    </div>
    <div class="subjectList">
      <div
        v-for="(item, index) in subjectList"
        :key="index"
        :class="['subject', tabSubjectId === item.id ? 'active' : '']"
        @click="handleClick(item, index)"
      >
        {{ item.name }}
      </div>
    </div>
    <template v-if="tabSubjectId || tabSubjectId === 0">
      <classRatio
        :key="'classRatio' + tabSubjectId"
        :subject-id="tabSubjectId"
        :top-data="topData"
      />
      <online
        :key="'online' + tabSubjectId"
        :subject-id="tabSubjectId"
        :top-data="topData"
      />
    </template>
  </div>
</template>

<script>
import {
  getstatclassoption,
  getstatsubjectoption,
} from "@/core/api/academic/common";
import { gradientColors } from "@/views/newExamAcademic/school/components/balance/getGradientColors";
import { getunionreportclasscompetitivesubjectbalanceanalysis } from "@/core/api/academic/union";
import classRatio from "./classRatio.vue";
import online from "./online.vue";
import classNums from "../../../components/classNums.vue";
import subjectCheck from "../../../components/subjectCheck.vue";
export default {
  name: "",
  components: {
    classRatio,
    online,
    classNums,
    subjectCheck,
  },
  props: {
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      classNumList: [],
      subjectList: [],
      subjectId: [],
      classNum: [],
      colorList: [],
      columns: [],
      tableData: [],
      tabSubjectId: null,
      checkSubject: [],
    };
  },
  mounted() {
    this.getClassNums();
  },
  methods: {
    handleClick(item) {
      this.tabSubjectId = item.id;
    },
    async getClassNums() {
      const res = await getstatclassoption({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.classNumList = res.data.data.map((item) => item.name);
      this.getSubjectList();
    },
    getColorts(overAverageTotal, rankTotal) {
      var arr1 = gradientColors("#93BFEB", "#ffffff", overAverageTotal + 1);
      var arr2 = gradientColors(
        "#ffffff",
        "#E86969",
        rankTotal - overAverageTotal + 1
      );
      arr1.pop();
      arr2.shift();
      return [...arr1, ...arr2];
    },
    totalCustomCell(record, index, ind) {
      let overAverageTotal = record.subjects[ind].overAverageTotal;
      let rank = record.subjects[ind].unionRank;
      let rankTotal = record.subjects[ind].rankTotal;
      return {
        style: {
          "background-color": this.getColorts(overAverageTotal, rankTotal)[
            rank - 1
          ],
        },
      };
    },
    async getSubjectList() {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        classNum: this.classNum,
        viewSchoolId: this.$route.query.schoolId,
        hasTotal: 1,
      });
      this.subjectList = res.data.data;
      this.checkSubject = res.data.data;
      if (res.data.data.length) {
        this.tabSubjectId = res.data.data[0].id;
        // this.getData();
      }
    },
    classNumChange(val) {
      this.classNum = val;
      this.getData();
    },
    subjectChange(val) {
      this.subjectId = val;
      this.getData();
    },
    hasCols(item, ind) {
      let cols = [
        {
          title: "平均分",
          dataIndex: "averageScore" + ind,
          align: "center",
          customCell: (record, index) =>
            this.totalCustomCell(record, index, ind),
          customRender: (text, record) => record.subjects[ind].averageScore,
        },
        {
          title: item.subjectId == 0 ? "班主任" : "授课教师",
          dataIndex: "teacherName" + ind,
          align: "center",
          customCell: (record, index) =>
            this.totalCustomCell(record, index, ind),
          customRender: (text, record) => record.subjects[ind].teacherName,
        },
      ];
      if (this.topData.isSchoolDisplayReportClassRank == 1) {
        cols = [
          ...cols,
          {
            title: "校排名",
            dataIndex: "schoolRank" + ind,
            align: "center",
            customCell: (record, index) =>
              this.totalCustomCell(record, index, ind),
            customRender: (text, record) => record.subjects[ind].schoolRank,
          },
          {
            title: "整体排名",
            dataIndex: "unionRank" + ind,
            align: "center",
            customCell: (record, index) =>
              this.totalCustomCell(record, index, ind),
            customRender: (text, record) => record.subjects[ind].unionRank,
          },
        ];
      }
      return cols;
    },
    async getData() {
      const res = await getunionreportclasscompetitivesubjectbalanceanalysis({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
        subjectIds: this.subjectId.length ? this.subjectId.join(",") : "",
        classNums: this.classNum.length ? this.classNum.join(",") : "",
      });
      this.tableData = res.data.data;
      let subjects = [];
      if (res.data.data.length) {
        subjects = res.data.data[0].subjects;
      }

      this.columns = [
        {
          title: "班级",
          dataIndex: "classNum",
          align: "center",
        },
        ...subjects.map((item, ind) => ({
          title: item.subjectName,
          dataIndex: "subject" + ind,
          align: "center",
          children: [...this.hasCols(item, ind)],
        })),
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../union.scss";
.filter {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.subjectList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 18px;
  .subject {
    height: 32px;
    border-radius: 4px;
    border: 1px solid #d5d6db;
    box-sizing: border-box;
    padding: 0 18px;
    margin-right: 8px;
    margin-bottom: 18px;
    line-height: 32px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #2474ed;
      color: #fff;
      border-color: #2474ed;
    }
  }
  .active {
    background-color: #2474ed;
    color: #fff;
    border-color: #2474ed;
  }
}
</style>
